import React from 'react';

function FooterBanner() {
	return (
		<div className="lg:min-h-[570px] flex items-center lg:justify-center bg-pink w-full py-[22px] px-[40px] xl:px-0">
			<div className="flex flex-col  lg:flex-row items-center  h-full justify-center xl:gap-[100px] md:gap-[50px] transition-[gap] ease-in-out">
				<div className="flex-2 flex flex-col gap-[30px] w-full xl:max-w-[566px] justify-center">
					<h2 className="font-bold text-[46px] leading-[54px]">Create your products with our application.</h2>
					<p className="lg:pr-[90px] text-[14px]">Dive into the realm of creativity with our innovative application that puts the power of design into your hands! Unleash your imagination and craft personalized gifts that reflect your unique style.</p>
					<p className="lg:pr-[90px] text-[14px]">
						Explore countless possibilities as you embark on a journey to create gifts that are cool and individual, just like you. Finding the perfect gift has never been this exciting - dive into a world of endless options and bring joy to every special occasion! With an intuitive
						interface and a multitude of design options, our app transforms ordinary presents into extraordinary personalized gifts - all at your fingertips.
					</p>
					<div className="flex flex-row items-center gap-[10px]">
						<a href="#" target="_blank" rel="noreferrer" title="App Store logo">
							<img src={require('../assets/images/appstore-logo.webp')} loading="lazy" alt="App Store" height="40" width={130} title="App Store" />
						</a>
						<a href="#" target="_blank" rel="noreferrer" title="Play Store logo">
							<img src={require('../assets/images/playstore-logo.webp')} loading="lazy" alt="Play Store" height="40" width={130} title="Play Store" />
						</a>
					</div>
				</div>
				<div className="flex items-center justify-center flex-0 ">
					<img src={require('../assets/images/FooterBanner.webp')} loading="lazy" alt="Footer" className=" h-[100%] self-center max-h-[526px]" title="Footer Banner" />
				</div>
			</div>
		</div>
	);
}

export default FooterBanner;
