import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getImage } from '../../server/server';
import '../../assets/style/Widget.css';

function ProductWidget({ product }: { product: any }) {
	const [loaded, setLoaded] = useState(false);
	const navigate = useNavigate();
	const imageType = product?.files[0]?.mimeType?.includes('image') ? 'image' : 'video';
	const image = getImage(product.files[0].id);

	const openProduct = () => {
		if (!!product?.optionIds?.length && !!product?.optionIds[0]) navigate(`/product/${product.urlSlug}--${product?.optionIds?.join(',')}`);
		else navigate(`/product/${product.urlSlug}--${product?.id ?? product?.productId}?standalone=true`);
	};

	return (
		<button className="product-widget rounded-[12px] overflow-hidden w-full flex flex-col  ease-in-out transition-all duration-[230ms] hover:scale-105" onClick={openProduct}>
			<div className="flex-1 flex flex-row w-full bg-babyBlue max-h-[290px] overflow-hidden">
				{imageType == 'image' ? (
					<img
						src={image}
						className={`flex-1 object-cover transition-opacity  ${loaded ? 'opacity-100' : 'opacity-0'}`}
						alt={product?.name ?? product.productName}
						title={product?.name ?? product.productName}
						onLoad={() => setLoaded(true)}
						style={{ transition: 'opacity 0.5s ease-in-out' }}
						loading="lazy"
					/>
				) : (
					<video style={{ pointerEvents: 'none', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} muted title={product?.name ?? product.productName}>
						<source src={image} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				)}
			</div>
			<div className="border border-babyBlue md:min-h-[56px] py-[12px] gap-[10px] rounded-b-[12px] px-[12px]  w-full flex md:flex-row flex-col justify-between md-items-center">
				<h3 className="text-left">{product?.name ?? product?.productName}</h3>
				<span className="md:text-right text-left">
					{product?.fromPrice ?? product?.minPrice} {product?.minPriceCurrency}
				</span>
			</div>
		</button>
	);
}

export default ProductWidget;
