import React from 'react';

interface ShippingMethodProps {
	availableShippingMethods: any[];
	shippingMethod: any;
	setShippingMethod: (a: any) => void;
}

function ShippingMethod({ availableShippingMethods, shippingMethod, setShippingMethod }: ShippingMethodProps) {
	return (
		<div className="w-full flex flex-col bg-white rounded-xl p-10 mt-5">
			<p className="text-[20px] leading-[28px] font-medium text-black">Shipping Method</p>
			<div className="mt-[30px]">
				{availableShippingMethods.map((method: any) => (
					<button className="flex flex-row items-center justify-between w-full py-[10px] " onClick={() => setShippingMethod(method)}>
						<div className="gap-[5px] flex flex-col items-start ">
							<p className="text-[16px] leading-[20px] text-black font-normal">{method.name}</p>
							<p className="text-[12px]  text-black font-normal line-clamp-1 text-left">{method.description}</p>
						</div>
						<div className="ml-[10px] flex items-center justify-center border border-laykaDarkPurple min-h-[16px] min-w-[16px] rounded-full cursor-pointer">
							<div className={`min-w-[8px] min-h-[8px] bg-laykaPink ${shippingMethod.id === method.id ? 'block' : 'hidden'} rounded-full`}></div>
						</div>
					</button>
				))}
			</div>
		</div>
	);
}

export default ShippingMethod;
