import React, { useEffect, useState } from 'react';
import { getImage, getSymbolsForProduct } from '../../../server/server';
import Loading from '../../../common/Loading';
type Type = {
	addSymbol: any;
	productId?: string;
};
function AddSymbols({ addSymbol, productId = '' }: Type) {
	const [symbols, setSymbols] = useState([]);
	const [loadingSymbols, setLoadingSymbols] = useState(true);

	useEffect(() => {
		getSymbols();
	}, []);

	const getSymbols = async () => {
		const resp = await getSymbolsForProduct(productId);
		console.log(resp);
		if (resp.status === 200) {
			setSymbols(resp.data);
			setLoadingSymbols(false);
		}
	};
	return (
		<div className="border-t border-t-[#D4C4F6] mt-[30px] pt-[25px]">
			<div className="flex flex-row flex-wrap gap-y-[25px] gap-x-[16px]  pb-[50px] ">
				{symbols.map((symbol: any) => (
					<SymbolButton symbol={getImage(symbol?.imageId)} imageId={symbol?.imageId} addSymbol={addSymbol} key={symbol?.name} />
				))}
			</div>
		</div>
	);
}
const SymbolButton = ({ symbol = '', addSymbol, imageId = '' }: { symbol: string; imageId: string; addSymbol: ({ symbol, imageId }: { symbol: string; imageId: string }) => void }) => {
	const [loaded, setLoaded] = useState(false);
	return (
		<button className=" w-[69px] h-[69px] transition-all duration-[230ms] hover:scale-[1.2] relative" onClick={() => addSymbol({ symbol, imageId })}>
			<img src={symbol} alt="Symbol" width={69} height={69} className="object-fill" onLoad={() => setLoaded(true)} />
			{!loaded && (
				<div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
					<Loading height={40} />
				</div>
			)}
		</button>
	);
};

export default AddSymbols;
