import React, { useEffect, useMemo, useState } from 'react';
import { Link, Navigate, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useUser } from '../../auth/UseUser';
import NavBar from '../NavBar';
import ProductGroup from './ProductGroup';
import { getAllProducts, getCategoryDescription } from '../../server/server';
import Loading from '../../common/Loading';
import { productTypeLabelConverter } from '../../Constants';
import Markdown from 'react-markdown';
import SEO, { DEFAULT_TITLE } from '../seo/seo';
import { capitalizeFirstLetter } from '../../util/util';

function ProductsPage() {
	// const subCategory = searchParams.get('subcategory') ?? null;
	const { category_name, subcategory_name } = useParams();

	const categoryUrl = category_name ?? 'bar';
	const subCategoryUrl = subcategory_name ?? null;
	const [categories, setCategories] = useState<any[]>([]);
	const [loading, setLoading] = useState(true);
	const [categoryDescription, setCategoryDescription] = useState('');
	const [parentCategoryData, setParentCategoryData] = useState<any>(null);
	const [categoryData, setCategoryData] = useState<any>(null);

	const title = useMemo(() => {
		return parentCategoryData ? parentCategoryData?.name : categoryData?.name;
	}, [categoryData, parentCategoryData]);

	useEffect(() => {
		document?.getElementById('scroll-div')?.scroll(0, 0);
		setLoading(true);
		loadData();
		getProducts();
		getCategoryDetails();
	}, [categoryUrl, subCategoryUrl]);

	const loadData = async () => {
		await Promise.all([getProducts(), getCategoryDetails()]);
		setLoading(false);
	};

	const getProducts = async () => {
		const resp = await getAllProducts(!!subCategoryUrl ? subCategoryUrl : categoryUrl);
		if (resp.status === 200) {
			setCategories(resp.data);
		}
	};

	const getCategoryDetails = async () => {
		const resp = await getCategoryDescription(!!subCategoryUrl ? subCategoryUrl : categoryUrl);
		if (resp.status === 200) {
			setCategoryDescription(resp.data.categoryDescription);
			setParentCategoryData(resp.data.parentCategory);
			setCategoryData(resp.data);
		}
	};

	return (
		<div className="mx-auto w-full max-w-[1280px] self-center py-[60px]  flex flex-col min-h-[1000px] px-[20px]">
			<SEO title={loading ? DEFAULT_TITLE : `${capitalizeFirstLetter(title)} - Products - Layka `} description={categoryDescription} />
			{!loading && (
				<div className="text-[14px] mb-[12px] uppercase">
					<Link to={'/'}>Home</Link>{' '}
					{parentCategoryData && (
						<>
							{'/ '}
							<Link to={`/products/${parentCategoryData?.seo?.urlSlug}`}>{parentCategoryData?.name}</Link>
						</>
					)}
					<span className="cursor-default">/ {categoryData?.name}</span>
				</div>
			)}

			{!loading && (
				<>
					<div className="mb-[60px]">
						<h1 className="font-bold text-[70px] mb-[12px] capitalize absolute z-0 opacity-0">{categoryUrl}</h1>
						<h2 className="text-[14px] font-[400]">
							<Markdown>{categoryDescription}</Markdown>
						</h2>
					</div>
					<div className="gap-[60px] flex flex-col pb-[20px]">
						{categories.map((category: any, index: number) => (
							<ProductGroup groupName={category?.subcategory} hideTitle={!!subCategoryUrl} products={category?.products ?? []} key={index} />
						))}
					</div>
				</>
			)}
			{loading && (
				<div className="flex-1 flex flex-row items-center justify-center">
					<Loading />
				</div>
			)}
		</div>
	);
}

export default ProductsPage;
