import React, { useEffect, useState } from 'react';
import HomeScreenImage from '../../assets/images/HomeScreenImage.webp';

function HeaderImage() {
	const [image, setImage] = useState('');

	useEffect(() => {
		setTimeout(() => {
			setImage(HomeScreenImage);
		}, 10);
	}, []);

	return (
		<div>
			<div className="header-image-div p-[20px]" style={{ backgroundImage: !!image ? `url(${image})` : undefined }}>
				<div className=" w-full h-full flex flex-col items-center justify-center">
					<h1 className="text-[70px] font-bold text-white leading-[80px] text-center max-w-[853px]">Get your gifts in time for Christmas</h1>
					<div className="flex flex-row items-center gap-[10px] mt-[50px]">
						<a href="#" target="_blank" title="App Store Logo">
							<img src={require('../../assets/images/appstore-logo.webp')} loading="lazy" alt="App Store" height="40" width={130} title="App Store" />
						</a>
						<a href="#" target="_blank" title="Play Store Logo">
							<img src={require('../../assets/images/playstore-logo.webp')} loading="lazy" alt="Play Store" height="40" width={130} title="Play Store" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default HeaderImage;
