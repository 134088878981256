import { calculateAngleBetweenTwoVectors, findCloser, findEdgePoint, getIntersectionPointsWithHorizontal, getIntersectionPointsWithVertical } from './util';
import { fabric } from 'fabric';
import { DEFAULT_NEW_TEXT, DEFAULT_TEXT_CONFIG, DELETE_CONTROL, FAKE_RESTRICTION_AREA, FONTS, HideControls, HideControlsTextbox, LINE_CONFIGURATION, RESTRICTION_AREA_BORDER } from '../Constants';

export interface RestrictedArea {
	top: number;
	left: number;
	bottom: number;
	right: number;

	height?: number;
	width?: number;
}

export const restrictMovement = (obj: any, restrictedArea: { left: number; right: number; top: number; bottom: number }, accurate = true) => {
	const { top, left, width, height } = obj.getBoundingRect(accurate, accurate);
	const leftDistance = Math.max((obj?.left ?? 0) - left, 0);
	const topDistance = Math.max((obj?.top ?? 0) - top, 0);
	if (left < restrictedArea.left) obj.set('left', restrictedArea.left + leftDistance);
	if (restrictedArea.right < left + width) obj?.set('left', restrictedArea.right - (width - leftDistance));
	if (top < restrictedArea.top) obj?.set('top', restrictedArea.top + topDistance);
	if (top + height > restrictedArea.bottom) obj?.set('top', restrictedArea.bottom - height + topDistance);
};

export const restrictRotating = (obj: fabric.Object, restrictedArea: RestrictedArea) => {
	const { top, left, width, height } = obj.getBoundingRect(true, true);
	const { x, y } = obj.getCenterPoint();
	const [tl, tr, br, bl] = obj.getCoords(true, true);

	const objH: any = obj.getScaledHeight();
	const objW: any = obj.getScaledWidth();
	const r = Math.sqrt(objH * objH + objW * objW) / 2;

	let deltaAngle = 0;
	const objAngle = obj?.angle ?? 0;

	if (left < restrictedArea.left || left + width > restrictedArea.right || top < restrictedArea.top || top + height > restrictedArea.bottom) {
		if (left < restrictedArea.left) {
			const { point1, point2 } = getIntersectionPointsWithVertical({ x, y }, restrictedArea.left, r);
			const objEdgePoint = findEdgePoint([tl, tr, br, bl], 'x', 'lower');
			const intersection = findCloser(objEdgePoint, point1, point2, 'y');
			const intersectionPoint = { x: restrictedArea.left, y: intersection.y };
			deltaAngle = calculateAngleBetweenTwoVectors({ x, y }, intersectionPoint, { x: objEdgePoint.x, y: objEdgePoint.y });
			if (intersection === point2) deltaAngle *= -1;
		} else if (left + width > restrictedArea.right) {
			const { point1, point2 } = getIntersectionPointsWithVertical({ x, y }, restrictedArea.right, r);
			const objEdgePoint = findEdgePoint([tl, tr, br, bl], 'x', 'higher');
			const intersection = findCloser(objEdgePoint, point1, point2, 'y');
			const intersectionPoint = { x: restrictedArea.right, y: intersection.y };
			deltaAngle = calculateAngleBetweenTwoVectors({ x, y }, intersectionPoint, { x: objEdgePoint.x, y: objEdgePoint.y });
			if (intersection === point1) deltaAngle *= -1;
		} else if (top < restrictedArea.top) {
			const { point1, point2 } = getIntersectionPointsWithHorizontal({ x, y }, restrictedArea.top, r);
			const objEdgePoint = findEdgePoint([tl, tr, br, bl], 'y', 'lower');
			const intersection = findCloser(objEdgePoint, point1, point2, 'x');
			const intersectionPoint = { y: restrictedArea.top, x: intersection.x };
			deltaAngle = calculateAngleBetweenTwoVectors({ x, y }, intersectionPoint, { x: objEdgePoint.x, y: objEdgePoint.y });
			if (intersection === point1) deltaAngle *= -1;
		} else if (top + height > restrictedArea.bottom) {
			const { point1, point2 } = getIntersectionPointsWithHorizontal({ x, y }, restrictedArea.bottom, r);
			const objEdgePoint = findEdgePoint([tl, tr, br, bl], 'y', 'higher');
			const intersection = findCloser(objEdgePoint, point1, point2, 'x');
			const intersectionPoint = { y: restrictedArea.bottom, x: intersection.x };
			deltaAngle = calculateAngleBetweenTwoVectors({ x, y }, intersectionPoint, { x: objEdgePoint.x, y: objEdgePoint.y });
			if (intersection === point2) deltaAngle *= -1;
		}
		obj?.rotate(objAngle - deltaAngle);
	}
};
export const restrictScaling = (obj: any, restrictedArea: RestrictedArea) => {
	const { top, left, width, height } = obj.getBoundingRect(false, true);
	let scaleX = obj.scaleX;
	let scaleY = obj.scaleY;

	if (left + width > restrictedArea.right) {
		scaleX = (restrictedArea.right - obj.left) / obj.width;
		obj.set({ scaleX: scaleX });
	}
	if (left < restrictedArea.left) {
		scaleX = Math.min(obj.scaleX, (obj.left + obj.width * obj.scaleX - restrictedArea.left) / obj.width);
		obj.set({ scaleX: scaleX, left: restrictedArea.left });
	}

	if (top < restrictedArea.top) {
		scaleY = Math.min(scaleY, (obj.top + obj.height * scaleY - restrictedArea.top) / obj.height);
		obj.set({ scaleY: scaleY, top: restrictedArea.top });
	}
	if (top + height > restrictedArea.bottom) {
		scaleY = (restrictedArea.bottom - obj.top) / obj.height;
		obj.set({ scaleY: scaleY });
	}
	obj.setCoords();
};

export function adjustTextFontSize(textbox: any, maxWidth: number, maxHeight: number, scale: number) {
	textbox.initDimensions();
	var lines = textbox?._textLines?.length;
	let change = false;
	if (lines > 1) {
		while (lines > 1) {
			textbox.fontSize -= 1;
			textbox.setCoords();
			textbox.initDimensions();
			lines = textbox?._textLines?.length;
			change = true;
		}
	} else {
		if (textbox.calcTextWidth() < textbox.width && textbox.fontSize < textbox.maxFontSize) {
			const fontSize = textbox.fontSize;
			do {
				textbox.fontSize += 1;
				textbox.setCoords();
				textbox.initDimensions();
				lines = textbox?._textLines?.length;
			} while (lines == 1);
			textbox.fontSize -= 1;
			textbox.setCoords();
			textbox.initDimensions();
			if (fontSize != textbox.fontSize) change = true;
		}
	}
	if (change) {
		const top = textbox.initTop + (textbox.maxHeight - textbox.height * textbox.scaleX) / 2;
		textbox.top = top;
		textbox.setCoords();
		textbox.initDimensions();
	}
}

export const fitText = (obj: any, restrictedArea: RestrictedArea) => {
	const { top, height } = obj.getBoundingRect(false, true);
	if (top + height > restrictedArea.bottom) {
		const objHeight = restrictedArea.bottom - (top + height);
		obj.set({ top: top + objHeight });
	}
	obj.setCoords();
};

export const rescalingTextBox = (textComponent: any, restrictedArea: RestrictedArea) => {
	const { left, width } = textComponent.getBoundingRect(false, true);
	if (left + width > restrictedArea.right) {
		const width = restrictedArea.right - (textComponent?.left ?? 0);
		textComponent.set({ width: width });
	}
	if (left < restrictedArea.left) {
		const width = (textComponent?.width ?? 0) - (restrictedArea.left - (textComponent?.left ?? 0));
		textComponent.set({ left: restrictedArea.left, width });
	}
	textComponent.setCoords();
};

export const drawRestrictionArea = (virtualRestrictionArea: typeof FAKE_RESTRICTION_AREA, scaleValue: number, canvas?: fabric.Canvas) => {
	const [left, top, width, height] = virtualRestrictionArea.map((val: any) => val * scaleValue);
	canvas?.add(
		new fabric.Rect({
			top: top - 1,
			left: left - 1,
			height: height + 2,
			width: width + 2,
			fill: 'transparent',
			...LINE_CONFIGURATION,
		})
	);
};

export const computeScaleFactor = (imgW: any, canvasInfo: any) => {
	if (imgW === 0) return 1;
	const containerHeight = (Math.min(canvasInfo, 760) || 0) / imgW; //TODO: promeniti na clientHeight tako je bilo inicijalno....
	return containerHeight;
};
