import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as Dropdown } from '../../assets/icons/Dropdown.svg';
import { Country } from '../user-profile/sections/orders/OrderInterfaces';
import { getCountries } from '../../server/server';
import { Autocomplete, TextField } from '@mui/material';
import PaymentMethod from './PaymentMethod';
import ShippingMethod from './ShippingMethod';

function Checkout({ availablePayments, paymentMethod, setPaymentMethod, setShippingInfo, countries, shippingInfoError, availableShippingMethods, shippingMethod, setShippingMethod }: any) {
	const [deliveryMode, setDeliveryMode] = useState('standard');
	const [paymentMode, setPaymentMode] = useState('cash');
	const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

	const { register, handleSubmit, watch, reset, formState, getValues, setValue, control } = useForm({
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
			address: '',
			countryId: '',
			city: '',
			phoneNumber: '',
			zipCode: '',
		},
	});
	const { isDirty, isValid, errors } = formState;

	const formValues = watch();

	useEffect(() => {
		scrollToShippingAddress();
	}, []);

	const scrollToShippingAddress = () => {
		document?.getElementById('scroll-div')?.scroll(0, 630);
	};

	useEffect(() => {
		setShippingInfo(formValues);
	}, [formValues, setShippingInfo]);

	const handleCountryChange = (event: any, value: any) => {
		if (value) {
			const selectedCountry = countries.find((country: any) => country.id === value.id);
			if (selectedCountry) {
				setSelectedCountry(selectedCountry);
				setValue('countryId', selectedCountry.id);
			}
		}
	};
	return (
		<div className="col-span-3">
			<div className="w-full flex flex-col bg-white rounded-xl p-10">
				<p className="text-[20px] leading-[28px] font-medium text-black">Shipping Address</p>
				<div className={`border-b  py-3 mt-5 flex flex-row items-center ${shippingInfoError.firstName ? 'border-b-redError' : 'border-b-black'}`}>
					<input
						className={`w-full text-black text-[16px] leading-5 font-normal placeholder:text-[#6B6B6B] outline-none   ${shippingInfoError.firstName ? 'placeholder:text-redError' : ''}`}
						placeholder={`First name *`}
						{...register('firstName', { required: true })}
						style={{ backgroundColor: 'transparent' }}
						autoFocus
					/>
					<p className="text-redError text-[16px] leading-5 font-normal   text-nowrap">{shippingInfoError.firstName ? '*This field is required' : ''}</p>
				</div>
				<div className={`border-b  py-3 mt-5 flex flex-row items-center ${shippingInfoError.lastName ? 'border-b-redError' : 'border-b-black'}`}>
					<input
						className={`w-full text-black text-[16px] leading-5 font-normal placeholder:text-[#6B6B6B] outline-none   ${shippingInfoError.lastName ? 'placeholder:text-redError' : ''}`}
						placeholder={`Last name *`}
						{...register('lastName', { required: true })}
						style={{ backgroundColor: 'transparent' }}
					/>
					<p className="text-redError text-[16px] leading-5 font-normal   text-nowrap">{shippingInfoError.lastName ? '*This field is required' : ''}</p>
				</div>
				<div className={`border-b  py-3 mt-5 flex flex-row items-center ${shippingInfoError.email ? 'border-b-redError' : 'border-b-black'}`}>
					<input
						className={`w-full text-black text-[16px] leading-5 font-normal placeholder:text-[#6B6B6B] outline-none   ${shippingInfoError.email ? 'placeholder:text-redError' : ''}`}
						placeholder={`Email *`}
						{...register('email', { required: true })}
						style={{ backgroundColor: 'transparent' }}
					/>
					<p className="text-redError text-[16px] leading-5 font-normal   text-nowrap">{shippingInfoError.email ? '*This field is required' : ''}</p>
				</div>
				<div className={`  py-3 mt-5 flex flex-row items-center ${shippingInfoError.countryId ? 'border-b-redError' : 'border-b-black'}`}>
					<Autocomplete
						className="w-full"
						value={selectedCountry}
						onChange={handleCountryChange}
						id="country-select"
						options={countries || []}
						getOptionLabel={(option) => option?.name || ''}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Select country *"
								// error={!formValid.countryValid} helperText={!formValid.countryValid && 'Country is required'}
								variant="standard"
								sx={{
									'& .MuiInputBase-root::before': { borderColor: 'black' },
								}}
							/>
						)}
					/>
				</div>
				<div className={`border-b  py-3 mt-5 flex flex-row items-center ${shippingInfoError.city ? 'border-b-redError' : 'border-b-black'}`}>
					<input
						className={`w-full text-black text-[16px] leading-5 font-normal placeholder:text-[#6B6B6B] outline-none   ${shippingInfoError.city ? 'placeholder:text-redError' : ''}`}
						placeholder={`City *`}
						{...register('city', { required: true })}
						style={{ backgroundColor: 'transparent' }}
					/>
					<p className="text-redError text-[16px] leading-5 font-normal   text-nowrap">{shippingInfoError.city ? '*This field is required' : ''}</p>
				</div>
				<div className={`border-b  py-3 mt-5 flex flex-row items-center ${shippingInfoError.address ? 'border-b-redError' : 'border-b-black'}`}>
					<input
						className={`w-full text-black text-[16px] leading-5 font-normal placeholder:text-[#6B6B6B] outline-none   ${shippingInfoError.address ? 'placeholder:text-redError' : ''}`}
						placeholder={`Address *`}
						{...register('address', { required: true })}
						style={{ backgroundColor: 'transparent' }}
					/>
					<p className="text-redError text-[16px] leading-5 font-normal   text-nowrap">{shippingInfoError.address ? '*This field is required' : ''}</p>
				</div>

				<div className={`border-b  py-3 mt-5 flex flex-row items-center ${shippingInfoError.phoneNumber || shippingInfoError.phoneNumberInvalid ? 'border-b-redError' : 'border-b-black'}`}>
					<input
						className={`w-full text-black text-[16px] leading-5 font-normal placeholder:text-[#6B6B6B] outline-none   ${shippingInfoError.phoneNumber || shippingInfoError.phoneNumberInvalid ? 'placeholder:text-redError' : ''} ${shippingInfoError.phoneNumberInvalid ? '' : ''}`}
						placeholder={`Phone number *`}
						{...register('phoneNumber', {
							required: true,
							pattern: {
								value: /^(\+?\d{2,3})?\s?\d{2,3}\s?\d{3}\s?\d{3,4}$/,
								message: 'Invalid phone number format',
							},
						})}
						style={{ backgroundColor: 'transparent' }}
					/>
					<p className="text-redError text-[16px] leading-5 font-normal   text-nowrap">{shippingInfoError.phoneNumber ? '*This field is required' : ''}</p>
					<p className="text-redError text-[16px] leading-5 font-normal   text-nowrap">{shippingInfoError.phoneNumberInvalid ? '*Invalid phone number' : ''}</p>
				</div>
				<div className={`border-b  py-3 mt-5 flex flex-row items-center ${shippingInfoError.zipCode ? 'border-b-redError' : 'border-b-black'}`}>
					<input
						className={`w-full text-black text-[16px] leading-5 font-normal placeholder:text-[#6B6B6B] outline-none   ${shippingInfoError.zipCode ? 'placeholder:text-redError' : ''}`}
						placeholder={`Zip code *`}
						{...register('zipCode', { required: true })}
						style={{ backgroundColor: 'transparent' }}
					/>
					<p className="text-redError text-[16px] leading-5 font-normal   text-nowrap">{shippingInfoError.zipCode ? '*This field is required' : ''}</p>
				</div>
			</div>
			<ShippingMethod availableShippingMethods={availableShippingMethods} setShippingMethod={setShippingMethod} shippingMethod={shippingMethod} />
			<PaymentMethod availablePayments={availablePayments} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />
		</div>
	);
}

export default Checkout;
