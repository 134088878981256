import React from 'react';

interface PaymentMethodProps {
	availablePayments: any[];
	paymentMethod: any;
	setPaymentMethod: (a: any) => void;
}

function PaymentMethod({ availablePayments, paymentMethod, setPaymentMethod }: PaymentMethodProps) {
	return (
		<div className="w-full flex flex-col bg-white rounded-xl p-10 mt-5 transition">
			<p className="text-[20px] leading-[28px] font-medium text-black">Payment Method</p>
			<div className="mt-[30px]">
				{availablePayments.map((method: any) => (
					<button className="flex flex-row items-center w-full justify-between  py-[10px]" onClick={() => setPaymentMethod(method)}>
						<div>
							<p className="text-[16px] leading-[20px] text-black  font-normal text-left">{method.name}</p>
							<p className="text-[12px]  text-black font-normal line-clamp-1 text-left">{method.description}</p>
						</div>
						<div className="flex items-center justify-center border border-laykaDarkPurple min-h-[16px] min-w-[16px] rounded-full cursor-pointer">
							<div className={`min-w-[8px] min-h-[8px] bg-laykaPink ${paymentMethod?.id === method.id ? 'block' : 'hidden'} rounded-full`}></div>
						</div>
					</button>
				))}
			</div>
		</div>
	);
}

export default PaymentMethod;
